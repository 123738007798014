import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { ApolloProvider as BaseApolloProvider, HttpLink, split, from, HttpOptions } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient, Client } from 'graphql-ws';
import { ApolloLink } from '@apollo/client/link/core';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/browser';
import { User } from '@auth0/auth0-react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';

import { getStoredSelectedTeamId } from '@services/localStorage/user';
import { useAuthentication } from '@services/authentication_provider';
import { randomUUID } from '@utils/uuid';
import { NemoLoadingCentered } from '@components/Loading';
import { useSchemaHashLazyQuery } from '@generated/graphql';

import type { LogoutOptions } from '@auth0/auth0-react';

import { SchemaHashContext } from './schema_hash_provider';

const client = new ApolloClient({
  cache: new InMemoryCache(),
  connectToDevTools: import.meta.env.DEV,
});

// because Apollo doesn't export it, copied from https://github.com/apollographql/apollo-client/blob/main/src/link/utils/throwServerError.ts
type ServerError = Error & {
  response: Response;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: Record<string, any> | string;
  statusCode: number;
};

const errorLinkWithLogoutAndCurrentUser = ({
  logout,
  user,
}: {
  logout: (options?: LogoutOptions) => Promise<void>;
  user:
    | {
        email: string | null;
      }
    | User
    | undefined;
}) => {
  return onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        Sentry.captureException(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      });

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
      Sentry.captureException(`[Network error]: ${networkError}`);
    }

    const narrowedNetworkError = networkError as ServerError;
    if (narrowedNetworkError?.result === 'jwt expired') {
      const userDescriptor = user instanceof User ? user.name : user?.email ?? 'unknown';
      Sentry.captureMessage(`JWT expired for user ${userDescriptor}`);
      logout();
    }
  });
};

const customFetch: HttpOptions['fetch'] = (uri, options) => {
  const body = options?.body;
  let operationName = '';

  if (body !== null && body !== undefined) {
    const bodyParsed = JSON.parse(body as string);

    operationName = bodyParsed.operationName;
  }

  const url = new URL(String(uri));

  url.searchParams.append('op', operationName);

  return fetch(url, options);
};

const httpLink = split(
  (operation) => {
    return operation.getContext()['isAuthenticated'];
  },
  new HttpLink({
    uri: new URL('/graphql', import.meta.env.VITE_API_BASE_URL ?? 'http://localhost:3000').toString(),
    fetch: customFetch,
  }),
  new HttpLink({
    uri: new URL('/public/graphql', import.meta.env.VITE_API_BASE_URL ?? 'http://localhost:3000').toString(),
    fetch: customFetch,
  }),
);

const useWsClient = ({ onConnected }: { onConnected: () => void }) => {
  const { getAccessTokenSilently, user, isTokenAuth } = useAuthentication();
  const [wsClient, setWsClient] = useState<Client>();

  useEffect(() => {
    const client = createClient({
      url: new URL('/subscriptions/graphql', import.meta.env.VITE_API_BASE_URL ?? 'http://localhost:3000')
        .toString()
        .replace('http', 'ws'),

      connectionParams: async () => {
        return {
          token: await getAccessTokenSilently(),
          'X-TOKEN-AUTH': isTokenAuth ? 'APPLICATION' : 'AUTH0',
        };
      },
      retryAttempts: Infinity,
      retryWait: (attempt) => {
        return new Promise((resolve) => setTimeout(resolve, attempt < 20 ? 1000 : attempt < 40 ? 5000 : 30000));
      },
      shouldRetry: () => true,
      on: {
        connected: onConnected,
      },
    });

    setWsClient(client);

    return () => {
      client.dispose();
    };
  }, [user?.email, getAccessTokenSilently, onConnected]);

  return wsClient;
};

export const ApolloProvider = ({ children }: { children: React.ReactNode }) => {
  const { getAccessTokenSilently, isAuthenticated, user, isTokenAuth, logout } = useAuthentication();
  const [currentSchemaHash, setCurrentSchemaHash] = useState<string | undefined>(undefined);
  const [isSchemaHashOutdated, setIsSchemaHashOutdated] = useState(false);
  const [getSchemaHash] = useSchemaHashLazyQuery({ client });
  const cancelRef = useRef(null);

  const authLink = setContext(async (operation, { headers, ...rest }) => {
    const token = isAuthenticated ? await getAccessTokenSilently() : 'missing-token';
    const teamId = user?.email ? getStoredSelectedTeamId(user?.email) : undefined;

    const allHeaders = isAuthenticated
      ? {
          ...headers,
          Authorization: `Bearer ${token}`,
          'X-Request-ID': randomUUID(),
          'X-Request-Team-ID': teamId,
          'X-TOKEN-AUTH': isTokenAuth ? 'APPLICATION' : 'AUTH0',
        }
      : {
          ...headers,
          'X-Request-ID': randomUUID(),
        };

    return { ...rest, headers: allHeaders, isAuthenticated };
  });

  const validateSchemaHash = useCallback(
    (schemaHash: string) => {
      if (schemaHash && currentSchemaHash && schemaHash !== currentSchemaHash) {
        setIsSchemaHashOutdated(true);
      }

      if (schemaHash) {
        setCurrentSchemaHash(schemaHash);
      }
    },
    [currentSchemaHash],
  );

  const onConnected = useCallback(async () => {
    const { data } = await getSchemaHash({ fetchPolicy: 'network-only' });
    const schemaHash = data?.schemaHash;

    if (schemaHash) {
      validateSchemaHash(schemaHash);
    }
  }, [getSchemaHash, validateSchemaHash]);

  const wsClient = useWsClient({ onConnected });

  const errorLink = useMemo(() => errorLinkWithLogoutAndCurrentUser({ logout, user }), []);

  if (!wsClient) {
    return <NemoLoadingCentered />;
  }

  const wsLink = new GraphQLWsLink(wsClient);

  client.setLink(
    from([
      authLink,
      new ApolloLink((operation, forward) => {
        return forward(operation).map((data) => {
          const context = operation.getContext();
          const { response } = context;

          if (response?.headers) {
            const schemaHash = response.headers.get('X-AZAVA-SCHEMA-HASH');

            validateSchemaHash(schemaHash);
          }

          return data;
        });
      }),
      errorLink,
      split(
        ({ query }) => {
          const definition = getMainDefinition(query);

          return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        },
        wsLink,
        httpLink,
      ),
    ]),
  );

  return (
    <BaseApolloProvider client={client}>
      <SchemaHashContext.Provider value={{ isSchemaHashOutdated }}>{children}</SchemaHashContext.Provider>
      <AlertDialog isOpen={isSchemaHashOutdated} leastDestructiveRef={cancelRef} onClose={() => {}}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>You're on an outdated version of the app</AlertDialogHeader>
            <AlertDialogBody>Please refresh to continue</AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="purple" onClick={() => window.location.reload()}>
                Refresh
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </BaseApolloProvider>
  );
};
