import React, { ComponentProps } from 'react';
import * as chakra from '@chakra-ui/react';
import styled, { css } from 'styled-components';
import { Box } from '@chakra-ui/react';

import { Color } from '@styled/themes/v2';

export const Page = styled.div`
  font-family: 'Anthro';
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: auto;

  // if we decide we want to hide scrollbars
  //* ::-webkit-scrollbar {
  //  display: none;
  //}
`;

export const MarginSection = ({
  children,
  outerProps,
  midProps,
  innerProps,
  scrollable,
  fadeTop,
}: {
  children: React.ReactNode;
  outerProps?: React.ComponentProps<typeof Box>;
  midProps?: React.ComponentProps<typeof Box>;
  innerProps?: React.ComponentProps<typeof Box>;
  scrollable?: boolean;
  fadeTop?: boolean;
}) => (
  <Box
    w="100%"
    position="relative"
    overflowY={scrollable ? 'hidden' : undefined}
    zIndex="0"
    _before={
      fadeTop
        ? {
            content: '""',
            position: 'absolute',
            h: '14px',
            w: '100%',
            top: '0',
            zIndex: '11',
            background: 'linear-gradient(#ffffffff, #ffffff00)',
          }
        : undefined
    }
    {...outerProps}
  >
    <Box w="100%" h="100%" overflowY={scrollable ? 'auto' : undefined} position="relative" {...midProps}>
      <Box w="100%" maxW="824px" marginInline="auto" position="relative" {...innerProps}>
        {children}
      </Box>
    </Box>
  </Box>
);

export const Container = styled.div<{
  padding?: number;
  paddingInline?: number;
  maxWidth?: number;
  fullWidth?: boolean;
  fullHeight?: boolean;
  background?: string;
  center?: boolean;
  expand?: boolean;
  fitContentToContainer?: boolean;
}>`
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : '')};
  ${({ background }) => (background ? `background: ${background};` : '')}
  ${({ center }) => (center ? 'align-items: center;' : '')}
  ${({ center }) => (center ? 'justify-content: center;' : '')}
  ${({ fullWidth }) => (fullWidth ? 'width: 100%;' : '')}
  ${({ fullHeight }) => (fullHeight ? 'height: 100%;' : '')}
  ${({ fitContentToContainer }) => (fitContentToContainer ? 'min-width: 0;' : '')}
  flex-grow: ${({ expand }) => (expand === false ? '0' : '1')};
  padding: ${({ padding }) => padding ?? 0}px;
  ${({ paddingInline }) => (paddingInline !== undefined ? `padding-inline: ${paddingInline}px;` : '')}

  display: flex;
  position: relative;
`;

export const PrimaryContainer = styled(Container)<{
  scrollable?: boolean;
  scrollbarHidden?: boolean;
  visibleOverflow?: boolean;
}>`
  background: ${({ background }) => background ?? Color.WHITE};
  align-items: center;
  width: 100%;
  display: block;
  z-index: 0;
  ${({ scrollable, visibleOverflow }) =>
    scrollable
      ? css`
          overflow: auto;
          scrollbar-gutter: stable;
        `
      : css`
          overflow: ${visibleOverflow ? 'visible' : 'hidden'};
        `}
  ${({ scrollbarHidden }) =>
    scrollbarHidden &&
    css`
      ::-webkit-scrollbar {
        display: none;
      }
    `}
`;

export const Stack = styled(Container)<{
  gap?: number;
  scrollable?: boolean;
  scrollbarHidden?: boolean;
  noShrink?: boolean;
  visibleOverflow?: boolean;
}>`
  ${({ noShrink }) => noShrink && `flex-shrink: 0;`}
  flex-direction: column;
  ${({ gap }) =>
    gap
      ? css`
          gap: ${gap}px;
        `
      : ''}
  overflow: ${({ scrollable, visibleOverflow }) => (scrollable ? 'auto' : visibleOverflow ? 'visible' : 'hidden')};
  ${({ scrollbarHidden }) =>
    scrollbarHidden &&
    css`
      ::-webkit-scrollbar {
        display: none;
      }
    `}
`;

export const Row = styled(Container)<{
  gap?: number;
  noWrap?: boolean;
  spread?: boolean;
  spacedEvenly?: boolean;
  alignTop?: boolean;
}>`
  flex-wrap: ${({ noWrap }) => (noWrap ? 'nowrap' : 'wrap')};
  flex-direction: row;
  align-items: ${({ alignTop }) => (alignTop ? 'flex-start' : 'center')};
  ${({ gap }) => (gap ? `gap: ${gap}px;` : '')}
  ${({ spread }) =>
    spread
      ? css`
          justify-content: space-between;
          > div {
            flex-grow: 0 !important;
          }
        `
      : ''}
      ${({ spacedEvenly }) =>
    spacedEvenly &&
    css`
      > * {
        margin-right: auto;
      }
    `}
`;

export const WrappingGridRow = styled(Row)<{ $columnCount: number; $breakpoint: number }>`
  display: grid;
  grid-template-columns: repeat(${({ $columnCount }) => $columnCount}, 1fr);
  align-items: flex-start;

  @media (max-width: ${({ $breakpoint }) => $breakpoint}px) {
    grid-template-columns: 1fr;
  }
`;

export const DividedStack = ({
  gap,
  paddingInline,
  visibleOverflow,
  ...props
}: React.ComponentProps<typeof chakra.VStack> & {
  gap?: number;
  paddingInline?: number;
  visibleOverflow?: boolean;
}) => {
  const overflow = visibleOverflow === undefined ? undefined : visibleOverflow ? 'visible' : 'hidden';

  return (
    <chakra.VStack
      align="spread"
      {...props}
      divider={<chakra.Divider m="0 !important" />}
      gap={(gap ?? 0) / 2 + 'px'}
      paddingInline={(paddingInline ?? 0) + 'px'}
      w="100%"
      overflowY="scroll"
      overflow={overflow}
      sx={{ '::-webkit-scrollbar': { display: 'none' } }}
    />
  );
};

export const VStack = (props: ComponentProps<typeof chakra.VStack>) => {
  return <chakra.VStack align="flex-start" w="100%" gap="0" {...props} />;
};
