import { useCallback, useEffect, useState } from 'react';

import { useAuthentication } from '@services/authentication_provider';
import { getStoredSelectedTeamId } from '@services/localStorage/user';
import { randomUUID } from '@utils/uuid';

export function useRestEndpoint<T>({
  route,
  query,
  init,
}: {
  route: string;
  query?: URLSearchParams;
  init?: RequestInit;
}) {
  const { getAccessTokenSilently, isAuthenticated, user, isTokenAuth } = useAuthentication();
  const [token, setToken] = useState<string | null>(null);
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [called, setCalled] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    getAccessTokenSilently().then(setToken);
  }, [isAuthenticated, getAccessTokenSilently, setToken]);

  const requiresAuthentication = !route.includes('public');

  const fetcher = useCallback(
    async (initOverride?: RequestInit & { query?: URLSearchParams }) => {
      setLoading(true);
      setError(null);

      try {
        const url = new URL(route, import.meta.env.VITE_API_BASE_URL ?? 'http://localhost:3000');
        const queryParams = query ?? initOverride?.query;

        if (queryParams) {
          url.search = queryParams.toString();
        }

        const inits = { ...(init ?? {}), ...(initOverride ?? {}) };

        inits.headers = new Headers(inits.headers);
        inits.headers.set('X-Request-ID', randomUUID());

        if (requiresAuthentication) {
          if (!token) {
            // Break early if authentication is required, but we
            // haven't yet fetched the token. The callback will be
            // re-invoked once the token is set.
            return { data: null, loading: true, error: null, called } as const;
          }

          inits.headers.set('X-TOKEN-AUTH', isTokenAuth ? 'APPLICATION' : 'AUTH0');
          inits.headers.set('Authorization', `Bearer ${token}`);

          const teamId = user?.email ? getStoredSelectedTeamId(user.email) : undefined;

          if (teamId) {
            inits.headers.set('X-Request-Team-ID', teamId);
          }
        }

        setCalled(true);

        const response = await fetch(url, inits);

        if (response.status >= 400) {
          const message = await response.text();

          throw new Error(message ?? `HTTP ${response.status} ${response.statusText}`);
        }

        if (response.status === 204 || response.headers.get('content-length') === '0') {
          setData(null);
          setLoading(false);

          return { data: null, loading: false, error: null, called } as const;
        }

        const json = await response.json();

        setData(json);
        setLoading(false);

        return { data: json as T, loading: false, error: null, called } as const;
      } catch (e) {
        console.error(e);
        setError(e as Error);
        setLoading(false);

        return { data: null, loading: false, error: e as Error, called } as const;
      }
    },
    [route, init, token, user?.email],
  );

  return [fetcher, { data, loading, error, called }] as const;
}
