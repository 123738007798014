import TypedLocalStore from 'typed-local-store';

import { PortfolioOverviewTableSearch } from './portfolioTableSearch';
import { PortfolioOverviewTableState } from './portfolioOverviewTable';
import { Portfolio } from './portfolio';
import { LastViewedPortfolio } from './lastViewedPortfolio';
import { DealsState } from './deals';

const LS_KEY = '__AZAVA__';

interface Schema {
  [LS_KEY]: {
    users: User[];
    authState?: AuthState;
    tokenState?: TokenState;
  };
}

export interface User {
  email: string;
  selectedTeamId?: string;
  lastViewedPortfolios: LastViewedPortfolio[];
  portfolios: Portfolio[];
  portfolioOverviewTable: {
    state: PortfolioOverviewTableState;
    searches: PortfolioOverviewTableSearch[];
  };
  deals?: DealsState;
}

interface AuthState {
  value: string;
  redirectUrl: string;
}

interface TokenState {
  token: string;
  email: string;
}

// Naming and typing requirements by react-table
export interface SortBy {
  id: string;
  desc?: boolean;
}

const typedLocalStorage = new TypedLocalStore<Schema>();
const getStorage = () => typedLocalStorage.getItem(LS_KEY) ?? { users: [] };

export const getUserStore = (email: string): User => {
  const users = getStorage().users;

  return users.find(({ email: storedEmail }) => storedEmail === email) ?? defaultUserStore(email);
};

export const upsertUserStore = (user: User): void => {
  const storage = getStorage();
  const users = storage.users;
  const index = users.map(({ email }) => email).indexOf(user.email);

  if (index === -1) {
    users.push(user);
  } else {
    users[index] = user;
  }

  typedLocalStorage.setItem(LS_KEY, { ...storage, users });
};

const defaultUserStore = (email: string): User => ({
  email,
  lastViewedPortfolios: [],
  portfolios: [],
  portfolioOverviewTable: {
    state: { hiddenColumns: [] },
    searches: [],
  },
});

// Auth0
export const getAuthStateStore = () => typedLocalStorage.getItem(LS_KEY)?.authState;

export const updateAuthStateStore = (authState?: AuthState) => {
  const storage = getStorage();

  typedLocalStorage.setItem(LS_KEY, { ...storage, authState });
};

// Token storage
export const getTokenStateStore = () => typedLocalStorage.getItem(LS_KEY)?.tokenState;

export const updateTokenStateStore = (tokenState?: TokenState) => {
  const storage = getStorage();

  typedLocalStorage.setItem(LS_KEY, { ...storage, tokenState });
};
