import Lottie from 'react-lottie-player/dist/LottiePlayerLight';

const CryingUnicorn = ({ style }: React.ComponentProps<keyof JSX.IntrinsicElements>) => (
  <Lottie
    className="crying-unicorn-icon"
    loop
    play
    path="/lottie/crying-unicorn.json"
    rendererSettings={{ preserveAspectRatio: 'xMidYMax meet' }}
    style={style}
  />
);

export default CryingUnicorn;
