import { ReactNode, useEffect, useMemo, useState } from 'react';
import { httpBatchLink, createWSClient, wsLink, splitLink } from '@trpc/client';
import { inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import { createTRPCReact, inferReactQueryProcedureOptions } from '@trpc/react-query';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { randomUUID } from '@utils/uuid';

import type { TRPCRouter } from '#trpc';

import { useAuthentication } from './authentication_provider';
import { useSelectedTeamId } from './localStorage/user';

const trpc: ReturnType<typeof createTRPCReact<TRPCRouter>> = createTRPCReact<TRPCRouter>();
type ReactQueryOptions = inferReactQueryProcedureOptions<TRPCRouter>;
type RouterInputs = inferRouterInputs<TRPCRouter>;
type RouterOutputs = inferRouterOutputs<TRPCRouter>;

function getWebSocketOverrideForToken(token: string) {
  class WebSocketOverride extends WebSocket {
    constructor(url: string | URL, protocols?: string | string[]) {
      if (protocols) {
        console.error('Ignoring ws protocols');
      }
      super(url, ['AzavaToken', token]);
    }
  }

  return WebSocketOverride;
}

const TRPCProvider = ({ children }: { children: ReactNode }) => {
  const { getAccessTokenSilently, isAuthenticated, user, isTokenAuth } = useAuthentication();
  const [token, setToken] = useState<string | null>(null);
  const [headers, setHeaders] = useState<Record<string, string | undefined>>({});
  const [teamId] = useSelectedTeamId(user?.email ?? 'public@azava.com');

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    getAccessTokenSilently().then(setToken);
  }, [isAuthenticated, getAccessTokenSilently, setToken]);

  useEffect(() => {
    // TODO: consider unauthenticated requests
    const newHeaders: Record<string, string | undefined> = {};

    if (!token) {
      // Break early if authentication is required, but we
      // haven't yet fetched the token. The callback will be
      // re-invoked once the token is set.
      return;
    }

    newHeaders['X-TOKEN-AUTH'] = isTokenAuth ? 'APPLICATION' : 'AUTH0';
    newHeaders['Authorization'] = `Bearer ${token}`;

    if (teamId) {
      newHeaders['X-Request-Team-ID'] = teamId;
    }

    setHeaders(newHeaders);
  }, [token, teamId, isTokenAuth]);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            refetchOnWindowFocus: false,
          },
        },
      }),
  );

  const wsClient = useMemo(
    () =>
      createWSClient({
        url: new URL('/subscriptions/trpc', import.meta.env.VITE_API_BASE_URL ?? 'http://localhost:3000')
          .toString()
          .replace(/^http/, 'ws'),
        WebSocket: token ? getWebSocketOverrideForToken(token) : WebSocket,
      }),
    [token],
  );

  const trpcClient = useMemo(
    () =>
      trpc.createClient({
        links: [
          splitLink({
            condition: (op) => op.type === 'subscription',
            true: wsLink({ client: wsClient }),
            false: httpBatchLink({
              url: new URL('/api/trpc', import.meta.env.VITE_API_BASE_URL ?? 'http://localhost:3000'),
              headers() {
                return {
                  ...headers,
                  'X-Request-ID': randomUUID(),
                };
              },
            }),
          }),
        ],
      }),
    [headers, wsClient],
  );

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
};

export type { ReactQueryOptions, RouterInputs, RouterOutputs };
export { trpc, TRPCProvider };
