import styled, { css } from 'styled-components';

import Spinner from '@/assets/icons/loading-spinner-nemo.svg?react';

export const SmallWrap = styled.div`
  width: 150px;
  margin-inline: auto;
  margin-top: 40px;
`;

export const NemoSpinner = styled(Spinner)<{
  $spinFrequency: number;
  $colourSpinFrequency: number;
  $radius: number;
  $gapFraction: number;
  $thickness: number;
  $time: number;
}>`
  ${({ $spinFrequency, $colourSpinFrequency, $radius, $gapFraction, $thickness, $time }) => {
    const circumference = 2 * Math.PI * $radius;
    const gap = circumference * $gapFraction;
    const spinVelocity = $spinFrequency === 0 ? 0 : 1 / $spinFrequency;
    const colourSpinVelocity = $colourSpinFrequency === 0 ? 0 : 1 / $colourSpinFrequency;
    const adjustedLoadVelocity = spinVelocity - colourSpinVelocity;
    const adjustedLoadFrequency = adjustedLoadVelocity === 0 ? 0 : 1 / adjustedLoadVelocity;
    const adjustedRadius = $radius + $thickness / 2;
    const width = 2 * adjustedRadius;
    const height = width;
    const velocityMs = Math.abs(adjustedLoadFrequency) * 1000;
    const delay = -($time % velocityMs);

    return css`
      width: ${width}px;
      height: ${height}px;
      > circle {
        stroke-dasharray: calc(${circumference - gap}) ${gap};
        cx: ${adjustedRadius};
        cy: ${adjustedRadius};
        r: ${$radius};
        stroke-width: ${$thickness};

        animation: loadSpinner ${Math.abs(adjustedLoadFrequency)}s linear infinite;
        animation-delay: ${delay}ms;

        @keyframes loadSpinner {
          0% {
            stroke-dashoffset: ${adjustedLoadFrequency > 0 ? circumference : 0};
          }
          100% {
            stroke-dashoffset: ${adjustedLoadFrequency < 0 ? circumference : 0};
          }
        }
      }

      animation: spinSpinner ${Math.abs($colourSpinFrequency)}s linear infinite;
      animation-delay: ${delay}ms;

      // spin animation
      @keyframes spinSpinner {
        0% {
          transform: rotate(${$colourSpinFrequency < 0 ? 359 : 0}deg);
        }
        100% {
          transform: rotate(${$colourSpinFrequency > 0 ? 359 : 0}deg);
        }
      }
    `;
  }}
`;
