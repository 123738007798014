import React, { useEffect, lazy } from 'react';

import { NemoSpinner, SmallWrap } from './styled';

const LoadingUnicorn = lazy(() => import('@components/Animations/LoadingUnicorn'));

export function SmallLoading() {
  return (
    <SmallWrap>
      <LoadingUnicorn />
    </SmallWrap>
  );
}

type NemoLoadingProps = {
  spinFrequency: number;
  colourSpinFrequency: number;
  radius: number;
  gapFraction: number;
  thickness: number;
  fadeIn: boolean;
};

const loadingDefaults: NemoLoadingProps = {
  spinFrequency: 2,
  colourSpinFrequency: 1.5,
  radius: 40,
  gapFraction: 0.25,
  thickness: 4,
  fadeIn: true,
};

export function NemoLoading(props: Partial<NemoLoadingProps> = {}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const mountTime = React.useRef(Date.now());
  const { spinFrequency, colourSpinFrequency, radius, gapFraction, thickness, fadeIn } = {
    ...loadingDefaults,
    ...props,
  };

  // manually set the circle radius: safari doesn't support changing the r attribute via css
  useEffect(() => {
    ref.current?.firstElementChild?.firstElementChild?.setAttribute('r', `${radius - thickness / 2}px`);
  }, [ref]);

  return (
    <div
      ref={ref}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: fadeIn ? 0 : 1,
        animation: fadeIn ? `fadeIn 1s 0.8s ease-in-out forwards` : undefined,
      }}
    >
      <NemoSpinner
        $thickness={thickness}
        $radius={radius}
        $spinFrequency={spinFrequency}
        $colourSpinFrequency={colourSpinFrequency}
        $gapFraction={gapFraction}
        $time={mountTime.current}
      />
    </div>
  );
}

export function NemoLoadingCentered() {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <NemoLoading />
    </div>
  );
}
