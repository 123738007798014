import { Box, Divider, Flex, HStack, Link, Text } from '@chakra-ui/react';
import { Link as RRLink } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

import LinkedInIcon from '@/assets/icons/social/linkedin_text.svg?react';
import { Avatar } from '@components/Avatar';
import Spacer from '@components/util/Spacer';
import { PublicProfileFromSlugQuery, usePublicProfileFromSlugLazyQuery } from '@generated/graphql';
import { getNameInitials } from '@utils/nameInitials';
import { isGqlSubtype } from '@utils/isGqlSubtype';
import { InvestorTagline } from '@pages/Profile/InvestorTagline';

function ProfileCompactCard({ slug }: { slug: string }) {
  const [fetch] = usePublicProfileFromSlugLazyQuery({ variables: { slug: slug } });
  const [data, setData] = useState<PublicProfileFromSlugQuery | null>(null);
  useEffect(() => {
    const fetchProfile = async () => {
      const { data } = await fetch();
      if (!data) return;
      setData(data);
    };
    fetchProfile();
  }, [slug]);
  if (!data) return null;
  return <ProfileCompactCardPresentational data={data} />;
}

function ProfileCompactCardPresentational({ data }: { data: PublicProfileFromSlugQuery }) {
  const publicProfile = useMemo(() => {
    if (isGqlSubtype(data?.publicProfileBySlug, 'Profile')) {
      return data.publicProfileBySlug;
    }
    return null;
  }, [data]);

  const linkedInUrl = useMemo(() => {
    if (!publicProfile?.linkedin) return undefined;
    const url = publicProfile.linkedin;
    if (!url.includes('linkedin.com/in/')) {
      return undefined;
    }
    if (!url.includes('https://') && !url.includes('http://')) {
      return 'https://' + url;
    }
    return url;
  }, [publicProfile?.linkedin]);

  if (!publicProfile) return null;
  const { imageUrl, fullname, slug } = publicProfile;
  const initials = getNameInitials({ name: fullname });
  const fundName = publicProfile?.roles[0]?.entityProfile?.fullname ?? undefined;

  return (
    <Box py="16px" minW="330px" maxW="440px">
      <HStack alignItems="center" justifyContent="space-between" px="20px">
        <Box>
          <Flex alignItems="center" gap="10px">
            <Link as={RRLink} to={`/${slug}`} fontSize="16px" fontWeight="semibold" color="purple.primary">
              {fullname}
            </Link>
            {linkedInUrl ? (
              <Link href={linkedInUrl} fontSize="14px" target="_blank">
                <Box border="1px solid #e4e4e4" borderRadius="10px" p="4px">
                  <LinkedInIcon color="#616161" width="8px" height="8px" />
                </Box>
              </Link>
            ) : null}
          </Flex>
          {fundName ? (
            <>
              <Spacer size={2} />
              <Text fontSize="14px" color="#626262">
                {fundName}
              </Text>
            </>
          ) : null}

          {publicProfile.publicProfileInvestments.length ? (
            <>
              <Spacer size={14} />

              <Text fontSize="14px" color="#626262">
                {`Invested in ${publicProfile.publicProfileInvestments.map((item) => item.company.fullname).join(', ')}`}
              </Text>
            </>
          ) : null}
        </Box>

        <Link
          as={RRLink}
          to={`/${slug}`}
          border="2px solid transparent"
          borderRadius="100px"
          _hover={{ borderColor: 'purple.primary' }}
        >
          <Avatar initials={initials ?? ''} src={imageUrl ?? undefined} size={48} borderRadius={48} />
        </Link>
      </HStack>

      {publicProfile?.descriptorsGeo.length ? (
        <>
          <Spacer size={16} />
          <Divider />
          <Spacer size={16} />
          <Box px="20px">
            <InvestorTagline publicProfile={publicProfile} fontSize={14} />
          </Box>
        </>
      ) : null}
    </Box>
  );
}
export { ProfileCompactCard, ProfileCompactCardPresentational };
