import { ReactElement, useEffect } from 'react';

import { truncateLongNames } from '@utils/text';

interface Props {
  title: string | string[];
  children?: ReactElement;
}

export const DocumentTitle = ({ children, title }: Props) => {
  const documentDefined = typeof document !== 'undefined';

  useEffect(() => {
    if (!documentDefined) return;
    const titleString = typeof title === 'string' ? truncateLongNames(title) : title.map(truncateLongNames).join(' ');
    const devString = import.meta.env.DEV ? 'dev| ' : '';
    document.title = `${devString}${titleString}`;
  }, []);

  return <>{children}</>;
};
