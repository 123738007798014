import { Flex, Text, Tag, Box } from '@chakra-ui/react';
import { useMemo } from 'react';

import Spacer from '@components/util/Spacer';

function InvestorTagline({
  publicProfile,
  fontSize,
}: {
  publicProfile: {
    descriptorsGeo: string[];
    descriptorsInvestorType: string[];
    descriptorsStage: string[];
    descriptorsMiscTags: string[];
  };
  fontSize?: number;
}) {
  const { descriptorsGeo, descriptorsInvestorType, descriptorsStage, descriptorsMiscTags } = publicProfile;
  const dealOwnerTextDescription = useMemo(() => {
    const parts = [];
    if (descriptorsStage.length > 0) {
      parts.push(descriptorsStage[0].toLowerCase());
    }

    const investorType = descriptorsInvestorType
      .map((tag) => (tag === 'Angel' ? 'angel investor' : tag))
      .map((tag) => (tag === 'Family Office' ? 'family office' : tag));
    if (investorType.length) {
      if (investorType.length > 1) {
        parts.push(investorType.slice(0, -1).join(', '));
        parts.push(`and ${investorType[investorType.length - 1]}`);
      } else {
        parts.push(investorType[0]);
      }
    } else {
      parts.push('investor');
    }

    const geo = descriptorsGeo.map((tag) => (tag === 'the Asia Pacific' ? 'the Asia Pacific region' : tag));
    if (geo.length > 4) {
      parts.push('investing globally');
    } else if (geo.length > 1) {
      parts.push('investing in');
      parts.push(geo.slice(0, -1).join(', '));
      parts.push(`and ${geo[geo.length - 1]}`);
    } else if (geo.length === 1) {
      parts.push('investing in');
      parts.push(geo[0]);
    }

    // Note: this is an approximation
    if (/^[aeiou]/i.test(parts[0])) {
      parts.unshift('An');
    } else {
      parts.unshift('A');
    }

    return parts.join(' ');
  }, [descriptorsGeo, descriptorsInvestorType, descriptorsStage]);

  const descriptorsTotalLength =
    descriptorsStage.length + descriptorsInvestorType.length + descriptorsGeo.length + descriptorsMiscTags.length;
  if (descriptorsTotalLength === 0) {
    return null;
  }

  return (
    <Box>
      <Text
        fontSize={fontSize ? fontSize + 'px' : '16px'}
        color="#616161"
        flexShrink={0}
        whiteSpace="wrap"
        mr="8px"
        lineHeight="1.4"
      >
        {dealOwnerTextDescription}
      </Text>
      {descriptorsMiscTags.length > 0 ? (
        <>
          <Spacer size={6} />
          <Flex gap="8px" wrap="wrap">
            {descriptorsMiscTags.map((tag) => {
              return (
                <Tag key={tag} borderRadius="4px" color="#666666" bg="#EDEDED" flexShrink={0} border="none">
                  {tag}
                </Tag>
              );
            })}
          </Flex>
        </>
      ) : null}
    </Box>
  );
}
export { InvestorTagline };
