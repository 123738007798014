import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { lazy, useEffect, useMemo, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { getAuthStateStore, updateAuthStateStore } from '@services/localStorage';
import { TokenAuthProvider, useAuthToken, useToken } from '@services/token_provider';
import { useUserContext } from '@services/user_provider';
import { NemoLoadingCentered } from '@components/Loading';

const Home = lazy(() => import('@pages/Home'));

const useAuthentication = () => {
  const tokenInfo = useAuthToken();
  const auth0Info = useAuth0();

  return useMemo(
    () => (tokenInfo.token ? { ...tokenInfo, isTokenAuth: true } : { ...auth0Info, isTokenAuth: false }),
    [tokenInfo, auth0Info],
  );
};

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const tokenInfo = useToken();

  return tokenInfo.token ? (
    <TokenAuthProvider>{children}</TokenAuthProvider>
  ) : (
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      useRefreshTokens
      useRefreshTokensFallback={true}
      cacheLocation={'localstorage'}
      onRedirectCallback={() => {
        const state = new URLSearchParams(window.location.search).get('state');
        const stateStore = getAuthStateStore();

        if (state && stateStore?.value === state && stateStore.redirectUrl) {
          navigate(stateStore.redirectUrl);
        } else {
          updateAuthStateStore(undefined);
          window.history.replaceState({}, document.title, window.location.pathname);
        }
      }}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      }}
    >
      {children}
    </Auth0Provider>
  );
};

// TODO I think this component can be removed?
// because of the split between AuthedApp and UnauthedApp, I don't think useAuthentication can ever error
// safer to keep it around just in case though
function RequireAuth({ children }: { children: ReactNode }) {
  const { logout, isAuthenticated, error: authError, isLoading: isAuthLoading } = useAuthentication();
  const { selectedTeam } = useUserContext();
  const [accessDenied, setAccessDenied] = useState(false);

  useEffect(() => {
    if (authError) {
      if (authError.message.startsWith('Access denied')) {
        setAccessDenied(true);
      }

      logout({
        logoutParams: {
          returnTo: authError.message.startsWith('Access denied')
            ? `${window.location.origin}/login?accessDenied=true`
            : undefined,
        },
      });
    }
  }, [authError, logout]);

  if (isAuthLoading || (isAuthenticated && !selectedTeam)) {
    return <NemoLoadingCentered />;
  }

  if (!isAuthenticated && !accessDenied) {
    return <Home />;
  }

  return <>{children}</>;
}

export { AuthProvider, useAuthentication, RequireAuth };
